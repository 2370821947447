import { useEffect, useState } from "react";
import { SubscriptionDataGridCols } from "./subscriptionGridCols";
import { DataTable } from "../../../../components/data-table";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicForm } from "../../../../components/form-builder/form";
import { GET_SUBSCRIPTIONS, SUBSCRIPTION_CREATE, SUBSCRIPTION_DELETE, SUBSCRIPTION_UPDATE } from "../../../../app/services/subscriptions.service";
import { Loader } from "../../../../components/loader";
import toast from "react-hot-toast";
import { GET_CATEGORIES } from "../../../../app/services/categories.service";
import { Search } from "../../../../components/search/search";

export const CustomerSubscriptionsData = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<any>([]);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const userRole = sessionStorage?.getItem("user_role");
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });
  const currency = [
    { label: 'US Dollar ($)', value: 'US Dollar ($)', name: 'US Dollar', code: 'USD', symbol: '$' },
    { label: 'Thai Baht (฿)', value: 'Thai Baht (฿)', name: 'Thai Baht', code: 'THB', symbol: '฿' },
    { label: 'Indonesian Rupiah (Rp)', value: 'Indonesian Rupiah (Rp)', name: 'Indonesian Rupiah', code: 'IDR', symbol: 'Rp' }

    // { label: 'Euro (€)', value: 'Euro (€)', name: 'Euro', code: 'EUR', symbol: '€' },
    // { label: 'US Dollar ($)', value: 'US Dollar ($)', name: 'US Dollar', code: 'USD', symbol: '$' },
    // { label: 'Indian Rupee (₹)', value: 'Indian Rupee (₹)', name: 'Indian Rupee', code: 'INR', symbol: '₹' },
    // { label: 'Egyptian Pound (£S)', value: 'Egyptian Pound (£S)', name: 'Egyptian Pound', code: 'EGP', symbol: '£S' },
    // { label: 'Omani Rial (ر.ع)', value: 'Omani Rial (ر.ع)', name: 'Omani Rial', code: 'OMR', symbol: 'ر.ع' },
    // { label: 'UAE Dirham (د.إ)', value: 'UAE Dirham (د.إ)', name: 'UAE Dirham', code: 'AED', symbol: 'د.إ' },
    // { label: 'Japanese Yen (¥)', value: 'Japanese Yen (¥)', name: 'Japanese Yen', code: 'JPY', symbol: '¥' },
    // { label: 'British Pound (£)', value: 'British Pound (£)', name: 'British Pound', code: 'GBP', symbol: '£' },
    // { label: 'Canadian Dollar (C$)', value: 'Canadian Dollar (C$)', name: 'Canadian Dollar', code: 'CAD', symbol: 'C$' },
    // { label: 'Australian Dollar (A$)', value: 'Australian Dollar (A$)', name: 'Australian Dollar', code: 'AUD', symbol: 'A$' },
    // { label: 'Swiss Franc (CHF)', value: 'Swiss Franc (CHF)', name: 'Swiss Franc', code: 'CHF', symbol: 'CHF' },
    // { label: 'Chinese Yuan (¥)', value: 'Chinese Yuan (¥)', name: 'Chinese Yuan', code: 'CNY', symbol: '¥' },
    // { label: 'Swedish Krona (kr)', value: 'Swedish Krona (kr)', name: 'Swedish Krona', code: 'SEK', symbol: 'kr' },
    // { label: 'New Zealand Dollar (NZ$)', value: 'New Zealand Dollar (NZ$)', name: 'New Zealand Dollar', code: 'NZD', symbol: 'NZ$' },
    // { label: 'South African Rand (R)', value: 'South African Rand (R)', name: 'South African Rand', code: 'ZAR', symbol: 'R' },
  ]

  const [createSubscription, setCreateSubscription] = useState<any>([
    {
      title: "dropdown",
      type: "dropdown",
      label: "Category",
      name: "category_uuid",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select category",
      error: "Please select category",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "text",
      label: "Plan Name",
      name: "plan_name",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please enter plan name",
      error: "Please enter plan name",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "number",
      label: "Plan Days",
      name: "days",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please enter plan days",
      error: "Please enter plan days",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "number",
      label: "Price",
      name: "price",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please enter price",
      error: "Please enter price",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "dropdown",
      label: "Currency",
      name: "currency",
      formFloating: false,
      value: "",
      option: currency,
      regex: "",
      required: true,
      placeholder: "Please select currency",
      error: "Please select currency",
      hideMandatoryStar: false,
    },
    {
      title: "textarea",
      type: "text",
      label: "Notes",
      name: "notes",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Please enter notes",
      error: "Please enter notes",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: "Create",
          action: "add",
          isCheckFormValidation: true,
          class: "btn btn-primary px-4 btn-sm f16 me-1 py-2 border-0",
        },
        {
          title: "Cancel",
          action: "cancel",
          class: "btn btn-outline-primary px-4 btn-sm f16",
        },
      ],
    },
  ]);

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",
      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "text",
      label: "Subscription Plan",
      name: "subscription_plan",
      formFloating: false,
      value: "",
      option: [
        { label: "1 day", value: 1 },
        { label: "3 days", value: 2 },
        { label: "5 days", value: 3 },
        { label: "7 days", value: 4 },
        { label: "10 days", value: 5 },
      ],
      regex: "",
      placeholder: "Select subscription plan",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "text",
      label: "Content Type",
      name: "content_type",
      formFloating: false,
      value: "",
      option: [
        { label: "Game", value: 1 },
        { label: "Videos", value: 2 },
        { label: "Blogs", value: 3 },
      ],
      regex: "",
      placeholder: "Select subscription type",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-4",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
        }
      ]
    },
  ])

  useEffect(() => {
    getSubscriptions(pageCount);
    getCategories();
  }, [])

  const getCategories = () => {
    GET_CATEGORIES({ limit: 100, page: 0 }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          res?.data?.rows.forEach((element: any) => {
            element.label = element?.name;
            element.value = element?.uuid;
          });
          const formData = createSubscription;
          formData[0].option = res?.data?.rows;
          setCreateSubscription([...formData]);
          setCategoryData([...res?.data?.rows])
        }
      }
    });
  }
  const getSubscriptions = (data: any) => {
    const pageData = data
    pageData.customer_uuid = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
    pageData.site_uuid = userRole === "CUSTOMER" ? params?.customer_site_uuid : params?.site_uuid
    setLoading(true);
    GET_SUBSCRIPTIONS(pageData).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data);
          setLoading(false);
        }
      }
    });
  }

  const onActions = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Edit") {
      setIsEdit(true);
      const formData = createSubscription;
      data.data.currency = `${data?.data?.currency_name} (${data?.data?.currency_symbol})`
      formData?.forEach((element: any) => {
        if (element?.type === "button") {
          for (let i = 0; i < element?.buttons?.length; i++) {
            const ele = element?.buttons;
            ele[0].title = "Update";
            ele[0].action = "edit";
          }
        }
        element.value = data?.data[element.name]
      });
      setCreateSubscription([...formData]);
      setShowCreatePopup(true);
    } else if (data?.type === "Delete") {
      setShowDeletePopup(true);
    }
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getSubscriptions(pageCount);
  };
  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getSubscriptions(pageCount);
  };

  const createSubscriptionData = () => {
    setShowCreatePopup(true);
    setIsEdit(false);
    const formData = createSubscription;
    formData?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].title = "Create";
          ele[0].action = "add";
        }
      }
      element.value = ""
    })
    setCreateSubscription([...formData])
  };

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = data?.formData;
      formData.customer_uuid = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
      formData.site_uuid = params?.site_uuid ? params?.site_uuid : params?.customer_site_uuid;
      const selectedCurrency = currency.find((data: any) => data?.value === formData.currency);
      formData.currency_code = selectedCurrency?.code;
      formData.currency_name = selectedCurrency?.name;
      formData.currency_symbol = selectedCurrency?.symbol;

      formData.metadata = {};
      delete formData['undefined']
      delete formData['currency']
      setLoading(true);
      SUBSCRIPTION_CREATE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { position: 'top-right', })
            setLoading(false);
            getSubscriptions(pageCount);
          }
        }
      });
    } else if (data?.type === "edit") {
      const formData = data?.formData;
      setLoading(true);
      SUBSCRIPTION_UPDATE(selectedData?.uuid, formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            getSubscriptions(pageCount);
          }
        }
      });
    } else if (data.type === "cancel") {
      setShowCreatePopup(false);
    }
  };
  const onSearchFormSubmit = (event: any) => { };
  const onDeleteUserConfirmation = () => {
    setLoading(true);
    SUBSCRIPTION_DELETE(selectedData?.uuid).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res.error, { position: 'top-right', })
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message, { position: 'top-right', })
          setLoading(false);
          setShowDeletePopup(false);
          getSubscriptions(pageCount);
        }
      }
    });
  };

  const onSearch = (value: any) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getSubscriptions(pageCount);
  }

  return (
    <div className="m-2 text-start">
      <div className="container-fluid  content-bg-color box-shadow py-2">
        <div className="row alignItems">
          <div className={`${userRole === "SUPERADMIN" ? "col-md-12" : "col-12"} text-end`} >
            <button
              className="btn btn-primary border-0"
              onClick={() => createSubscriptionData()}
            >
              Create Subscription
            </button>
          </div>
        </div>
        <hr className="my-2" />
        <div className="mt-2">
          {/* <DynamicForm
            userFormData={searchFormData}
            columns={4}
            isEdit={false}
            onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
            customClass={"display-block"}
            isNotEmptyFormAfterSubmit={true}
            cancelRoute={undefined}
          /> */}
          <Search onSearch={(e) => onSearch(e)} subscriptionSearch={true} />
        </div>
        <div className="mt-3 mb-2">
          <Loader LoadingReducer={loading} />
          <DataTable
            tableData={tableListData}
            TableCols={SubscriptionDataGridCols}
            onActions={onActions}
            onPageChange={onPageChange}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        </div>
        <div
          className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"
            }  ${showCreatePopup ? "modal-show" : "modal-hide"}`}
          tab-index="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header border-bottom-primary mx-4 px-0">
                <h1
                  className="modal-title fs-5 fw-light"
                  id="exampleModalLabel"
                >
                  <strong className="text-primary-medium fw-bold">
                    {isEdit ? "Edit" : "Create"} Subscription
                  </strong>
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowCreatePopup(false)}
                ></button>
              </div>
              <div className="modal-body px-4 text-start">
                <div className="row">
                  <DynamicForm
                    userFormData={createSubscription}
                    columns={2}
                    cancelRoute={""}
                    isEdit={false}
                    custom_button_text={"Login"}
                    onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                    customClass={"display-block"}
                    loading={""}
                    isNotEmptyFormAfterSubmit={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDeletePopup && (
          <>
            <div
              className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"
                }  ${showDeletePopup ? "modal-show" : "modal-hide"}`}
              tab-index="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1
                      className="modal-title fs-5 fw-light"
                      id="exampleModalLabel"
                    >
                      <strong className="text-primary-medium fw-bold">
                        Delete
                      </strong>
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setShowDeletePopup(false)}
                    ></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">
                            Are you sure you want to delete{" "}
                            <span className="fw-bold ">
                              {selectedData?.plan_name ||
                                ""}
                            </span>{" "}
                            ?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 text-end">
                      <button
                        type="button"
                        className="btn btn-primary px-4 btn-sm f16 me-3"
                        onClick={onDeleteUserConfirmation}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium"
                        onClick={() => setShowDeletePopup(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};
