import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicForm } from "../../../../components/form-builder/form";
import { BreadCrumb } from "../../../../components/bread-crumb";
import { GET_SITES, SITE_CREATE, SITE_DELETE, SITE_UPDATE } from "../../../../app/services/sites.service";
import toast from 'react-hot-toast';
import { Loader } from '../../../../components/loader';
import { GET_USERS } from "../../../../app/services/users.service";
import Countries from '../../../../app/utils/countries.json'
import TimeZones from './../../../../app/utils/time-zones.json';
import { GET_PAYMENT_PARTNERS } from "../../../../app/services/payment-partners.service";

export const SiteCreationForm = () => {
    const params = useParams();
    const navigation = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const userRole = sessionStorage?.getItem("user_role");
    const [selectedData, setSelectedData] = useState<any>([]);
    const [createSiteFormData, setCreateSiteFormData] = useState<any>([
        // {
        //   title: "input",
        //   type: "text",
        //   label: "Service Id",
        //   name: "service_id",
        //   formFloating: false,
        //   value: "",
        //   regex: "",
        //   required: true,
        //   placeholder: "Please enter service id",
        //   error: "Please enter service id",
        //   hideMandatoryStar: false,
        // },
        {
            title: "input",
            type: "text",
            label: "Site Name",
            name: "code",
            formFloating: false,
            value: "",
            required: true,
            regex: "",
            placeholder: "Please enter site name",
            error: "Please enter site name",
            hideMandatoryStar: false,
        },
        {
            title: "multiDropdown",
            type: "text",
            label: "Payment Partners",
            name: "payment_partners",
            formFloating: false,
            value: null,
            option: [],
            regex: "",
            required: false,
            placeholder: "Please select payment partners",
            error: "Please select payment partners",
            hideMandatoryStar: false,
            isParentDropDown: true
        },
        // {
        //     title: "dropdown",
        //     type: "text",
        //     label: "Country",
        //     name: "country",
        //     formFloating: false,
        //     value: null,
        //     option: Countries,
        //     regex: "",
        //     required: false,
        //     placeholder: "Please select country",
        //     error: "Please select country",
        //     hideMandatoryStar: false,
        // },
        // {
        //     title: "dropdown",
        //     type: "text",
        //     label: "Timezone",
        //     name: "timezone",
        //     formFloating: false,
        //     value: null,
        //     regex: "",
        //     option: [],
        //     required: false,
        //     placeholder: "Please select timezone",
        //     error: "Please select timezone",
        //     hideMandatoryStar: false,
        // },
        {
            title: "input",
            type: "text",
            label: "Contact Email",
            name: "contact_email",
            formFloating: false,
            value: null,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
            required: false,
            placeholder: "Please enter contact email",
            error: "Please enter contact email",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Contact Mobile",
            name: "contact_mobile",
            formFloating: false,
            value: null,
            regex: "",
            maxLength: "15",
            required: false,
            placeholder: "Please enter contact mobile",
            error: "Please enter contact mobile",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Website URL",
            name: "site_url",
            formFloating: false,
            value: "",
            regex: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=%]*)?$/,
            required: false,
            placeholder: "Please enter website URL",
            error: "Please enter website URL",
            column: params?.site_form_uuid ? "6" : "12",
            hideMandatoryStar: false,
        },
        {
            title: "file",
            type: "file",
            label: "Logo Image",
            name: "logo_url",
            value: null,
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload logo",
            error: "Please upload logo",
            hideMandatoryStar: true
        },
        {
            title: "file",
            type: "file",
            label: "Favicon Image",
            name: "favicon_url",
            value: null,
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload favicon",
            error: "Please upload favicon",
            hideMandatoryStar: true,
            // multipleImagesUpload: true,
        },
        {
            title: "input",
            type: "text",
            label: "Success Redirect URL",
            name: "success_redirect_url",
            formFloating: false,
            value: "",
            regex: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=%]*)?$/,
            required: true,
            placeholder: "Please enter success redirect URL",
            error: "Please enter success redirect URL",
            column: "6",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Cancel Redirect URL",
            name: "cancel_redirect_url",
            formFloating: false,
            value: "",
            regex: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=%]*)?$/,
            required: true,
            placeholder: "Please enter cancel redirect URL",
            error: "Please enter cancel redirect URL",
            column: "6",
            hideMandatoryStar: false,
        },
        {
            title: "formArray",
            label: 'Meta Data',
            name: 'metadata',
            fields: [
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: "Create",
                    action: "add",
                    isCheckFormValidation: true,
                    class: "btn btn-primary px-4 btn-sm f16 me-1 py-2 border-0",
                },
                {
                    title: "Cancel",
                    action: "cancel",
                    class: "btn btn-outline-primary px-4 btn-sm f16",
                },
            ],
        },
    ]);

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Customers",
            value: "Customers",
            routerLink: "/dashboard/customers",
            isActive: false,
        },
        {
            label: "Customer Name",
            value: "Customer",
            routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
            isActive: false,
        },
        {
            label: "Customer Name",
            value: "Sites",
            routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
            isActive: true,
        },
    ]);

    useEffect(() => {
        if (userRole === "SUPERADMIN") {
            const customerId = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
            getCustomerDataById(customerId);
        }
    }, [])

    const getCustomerDataById = (userUuid: any) => {
        setLoading(true);
        GET_USERS({ uuid: userUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const data = breadcrumbInfo;
                    data[1].value = res?.data?.rows[0]?.name;
                    setBreadcrumbInfo([...data]);
                    setLoading(false);
                }
            }
        });
    }

    const getSecondsToTime = (d: any) => {
        d = Number(d);
        let num = Math.sign(d) === -1 ? '-' : '+';
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var hDisplay = h > 0 ? (h > 9 ? h : '0' + h) + ":" : "00:";
        var mDisplay = m > 0 ? (m > 9 ? m : '0' + m) : "00";
        return `${num} ${hDisplay} ${mDisplay}`;
    }

    useEffect(() => {
        getPaymentPartnersData();
        // const timeZonesData = TimeZones;
        // const updatedTimeZoneData = [];
        // for (let i = 0; i < timeZonesData.length; i++) {
        //     const element = timeZonesData[i];
        //     updatedTimeZoneData?.push(
        //         {
        //             value: getSecondsToTime(element?.offset),
        //             label: `${element?.name} (${getSecondsToTime(element?.offset)})`
        //         }
        //     )
        // }
        // const siteFormData = createSiteFormData;
        // siteFormData[3].option = updatedTimeZoneData
        // setCreateSiteFormData([...siteFormData])
        if (params?.site_form_uuid) {
            getSiteDataById(params?.site_form_uuid)
        }
    }, [])

    const getPaymentPartnersData = () => {
        setLoading(true);
        GET_PAYMENT_PARTNERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const paymnetPartnerData: any = [];
                    response?.forEach((el: any) => (
                        paymnetPartnerData?.push({
                            label: el?.code,
                            value: el?.uuid
                        })
                    ))
                    const siteFormData = createSiteFormData
                    siteFormData[1].option = paymnetPartnerData
                    setCreateSiteFormData([...siteFormData])
                    setLoading(false);
                }
            }
        });
    }

    const getSiteDataById = (siteUuid: any) => {
        // setLoading(true);
        GET_SITES({ user_uuid: params?.customer_uuid, uuid: siteUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setSelectedData(res?.data?.rows[0])
                    const formData = createSiteFormData;
                    res.data.rows[0].success_redirect_url = res?.data?.rows[0].metadata.success_redirect_url;
                    res.data.rows[0].cancel_redirect_url = res?.data?.rows[0].metadata.cancel_redirect_url;

                    console.log('res?.data?.rows[0]', res?.data?.rows[0]);
                    delete res?.data?.rows[0].metadata.cancel_redirect_url;
                    delete res?.data?.rows[0].metadata.success_redirect_url;

                    // res.data.rows[0] = Object.keys(res?.data?.rows[0])
                    //     .filter(key => key === "cancel_redirect_url" || key === "success_redirect_url") // Filter by key name
                    //     .reduce((result: any, key) => {
                    //         result[key] = res?.data?.rows[0][key];
                    //         return result;
                    //     }, {});
                    formData.splice(2, 0,
                        {
                            title: "input",
                            type: "text",
                            label: "Service Id",
                            name: "service_id",
                            formFloating: false,
                            value: "",
                            regex: /[A-Z,a-z,0-9]$/,
                            required: true,
                            placeholder: "Please enter service id",
                            error: "Please enter service id",
                            hideMandatoryStar: false,
                            column: "6",
                        }
                    )
                    formData?.forEach((element: any) => {
                        if (element?.type === "button") {
                            for (let i = 0; i < element?.buttons?.length; i++) {
                                const ele = element?.buttons;
                                ele[0].title = "Update";
                                ele[0].action = "edit";
                            }
                        }
                        if (element.name === 'payment_partners') {
                            const selectedPaymentsData = JSON.parse(res?.data?.rows[0][element.name]);
                            let result = [];
                            if (selectedPaymentsData.length > 0) {
                                result = element?.option.filter((o1: any) => !selectedPaymentsData.some((o2: any) => o1.value === o2));
                            }
                            element.value = result;
                        } else if (element.name === 'metadata') {
                            const headerData = res?.data?.rows[0][element.name];
                            element.fields = [];
                            for (var key in headerData) {
                                if (headerData.hasOwnProperty(key)) {
                                    element.fields.push(
                                        {
                                            fields: [
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Key",
                                                    formFloating: false,
                                                    name: "key",
                                                    value: key,
                                                    required: true,
                                                    placeholder: "Please enter key",
                                                    error: "Please enter key",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Value",
                                                    formFloating: false,
                                                    name: "Value",
                                                    value: headerData[key],
                                                    required: true,
                                                    placeholder: "Please enter value",
                                                    error: "Please enter value",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    type: "button",
                                                    class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                                                    buttons: [
                                                        {
                                                            title: 'Add',
                                                            icons: 'bi bi-plus-circle',
                                                            action: 'addHeader',
                                                            class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                                        }
                                                    ]
                                                },
                                            ]
                                        }
                                    )
                                }
                            }

                            if (element.fields.length > 1) {
                                element.fields?.forEach((subElement: any, index: number) => {
                                    if ((element.fields.length - 1) !== index)
                                        subElement.fields[2].buttons = [
                                            {
                                                title: 'Delete',
                                                icons: 'bi bi-trash',
                                                action: 'deleteHeader',
                                                class: 'btn btn-danger px-4 btn-sm f16 me-1'
                                            }
                                        ]
                                });
                            }
                        } else {
                            element.value = res?.data?.rows[0][element.name];
                        }
                    });

                    setCreateSiteFormData([...formData]);
                    setLoading(false);
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            let paymentPartnerUUIDs = [];
            const paymentData = data?.formData?.payment_partners;
            if (paymentData.length > 0) {
                paymentPartnerUUIDs = paymentData?.map((item: any) => item.value);
            }
            const formData = data?.formData;
            formData.customer_uuid = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
            formData.license_start_dt = null;
            formData.license_expiry_dt = null;
            formData.default_language = 'ENGLISH';
            formData.payment_partners = paymentPartnerUUIDs;
            formData.country = '';
            formData.timezone = '';

            if (formData?.success_redirect_url) {
                formData?.metadata.push({ key: "success_redirect_url", value: formData?.success_redirect_url });
            }
            if (formData?.cancel_redirect_url) {
                formData?.metadata.push({ key: "cancel_redirect_url", value: formData?.cancel_redirect_url });
            }

            if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            delete formData['undefined'];
            setLoading(true);
            SITE_CREATE(formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res.error, { position: "top-right" });
                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        if (userRole === "SUPERADMIN") {
                            navigation(`/dashboard/customers/${params?.customer_uuid}/sites`)
                        } else {
                            navigation(`/dashboard/sites`)
                        }
                    }
                }
            });
        }
        else if (data?.type === "edit") {
            const formData = data?.formData
            const paymentData = data?.formData?.payment_partners;
            console.log('formData ---------', formData, formData?.metadata);
            if (formData?.success_redirect_url) {
                formData?.metadata.push({ key: "success_redirect_url", value: formData?.success_redirect_url });
            }
            if (formData?.cancel_redirect_url) {
                formData?.metadata.push({ key: "cancel_redirect_url", value: formData?.cancel_redirect_url });
            }
            if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            let paymentPartnerUUIDs = [];
            if (paymentData.length > 0) {
                paymentPartnerUUIDs = paymentData?.map((item: any) => item.value);
            }
            formData.payment_partners = paymentPartnerUUIDs;
            console.log('formData', formData, formData?.metadata);

            setLoading(true);
            SITE_UPDATE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        if (userRole === "SUPERADMIN") {
                            navigation(`/dashboard/customers/${params?.customer_uuid}/sites`)
                        } else {
                            navigation(`/dashboard/sites`)
                        }
                    }
                }
            });
        }
        else if (data?.type === "cancel") {
            if (userRole === "SUPERADMIN") {
                navigation(`/dashboard/customers/${params?.customer_uuid}/sites`)
            } else {
                navigation(`/dashboard/sites`)
            }
        } else if (data?.type === 'addHeader') {

            // createSiteFormData, setCreateSiteFormData
            const form = createSiteFormData;
            form[event.index].fields.push(
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            )
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setCreateSiteFormData([...form])
        } else if (data?.type === 'deleteHeader') {
            const form = createSiteFormData;
            form[event.index].fields.splice(event.sunIndex, 1);
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setCreateSiteFormData([...form]);
        }
    }
    const onDropdownSelection = (event: any) => {
        console.log('event', event);
        const siteFormData = createSiteFormData;
        if (selectedData?.uuid) {
            if (event?.data.length === 1 && event?.data[0].label === "STRIPE" && siteFormData[2].name === "service_id") {
                // siteFormData[1].option = paymnetPartnerData
                siteFormData[2].required = false;
                setCreateSiteFormData([...siteFormData]);
            } else {
                siteFormData[2].required = true;
                // if (siteFormData[2].name !== "service_id") {
                //     siteFormData.splice(2, 0,
                //         {
                //             title: "input",
                //             type: "text",
                //             label: "Service Id",
                //             name: "service_id",
                //             formFloating: false,
                //             value: siteFormData[2].value,
                //             regex: /[A-Z,a-z,0-9]$/,
                //             required: true,
                //             placeholder: "Please enter service id",
                //             error: "Please enter service id",
                //             hideMandatoryStar: false,
                //             column: "6",
                //         }
                //     )
                // }
                setCreateSiteFormData([...siteFormData]);
            }
        }
    }
    return (
        <div className='container-fluid content-bg-color'>
            {userRole !== "CUSTOMER" && (
                <div className="row">
                    <div className='col-md-12'>
                        <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
                    </div>
                </div>
            )}
            <div className='row border-bottom-primary mt-2'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>{params?.site_form_uuid ? "Edit" : "Create"} Site</h4>
                </div>
            </div>
            <Loader LoadingReducer={loading} />
            <div className="row my-4">
                <DynamicForm
                    userFormData={createSiteFormData}
                    columns={2}
                    cancelRoute={""}
                    isEdit={false}
                    custom_button_text={"Login"}
                    onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                    onParentDropdownEvent={(e) => onDropdownSelection(e)}
                    customClass={"display-block"}
                    loading={""}
                    isNotEmptyFormAfterSubmit={true}
                />
            </div>
        </div>
    )
}